import React from 'react'
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import logo from "../../assets/AdminLTELogo.png";
export const Sidebar = () => {
    // const { email, id } = useSelector(state => state.auth);
    // const urlPerfil = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/imagenes/usuario/${id}/fotoperfil`
    return (
        <div>
            <div className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                <Link to="/" className="brand-link">
                    <img src={logo} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light">Calendapp</span>
                </Link>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            {/* <img src={urlPerfil} className="img-circle elevation-2" alt="imagenUser" /> */}
                            <span className="brand-text font-weight-light" style={{color: "white"}}>Administrador</span>
                        </div>
                        <div className="info">
                            {/* <Link to="/" className="d-block">{email}</Link> */}
                        </div>
                    </div>
                    {/* SidebarSearch Form */}
                    {/* <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw" />
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {/* Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library */}
                            <li className="nav-header">MENU</li>
                            <li className="nav-item">
                                <Link to="./home" className="nav-link">
                                    <i className="nav-icon fas fa-chart-pie" />
                                    <p>
                                        Inicio
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="./usuario" className="nav-link">
                                    <i className="nav-icon fas fa-users" />
                                    <p>
                                        Usuarios
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="./ciudades" className="nav-link" >
                                    <i className="nav-icon fas fa-city" />
                                    <p>
                                        Ciudades
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="./nombreimpuesto" className="nav-link" >
                                    <i className="nav-icon fas fa-paste" />
                                    <p>
                                        Nombre Impuestos
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="./detalleimpuesto" className="nav-link" >
                                    <i className="nav-icon fas fa-file-invoice-dollar" />
                                    <p>
                                        Impuestos
                                    </p>
                                </Link>
                            </li>
                             <li className="nav-item">
                                <Link to="./editarimpuestos" className="nav-link" >
                                    <i className="nav-icon fas fa-pencil" />
                                    <p>
                                        Editar Impuestos
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="./membresias" className="nav-link" >
                                    <i className="nav-icon fas fa-file-invoice-dollar" />
                                    <p>
                                        Membresias
                                    </p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="./compraMembresias" className="nav-link" >
                                    <i className="nav-icon fas fa-file-invoice-dollar" />
                                    <p>
                                        Compra Membresias
                                    </p>
                                </Link>
                            </li>




                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </div>
        </div>

    )
}
